<template>
    <div class="pass-container">
        <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-form-model-item label="原密码">
                <a-input v-model="form.password" class="w200"/>
            </a-form-model-item>
            <a-form-model-item label="新密码">
                <a-input v-model="form.password1" class="w200"/>
            </a-form-model-item>
            <a-form-model-item label="确认新密码">
                <a-input v-model="form.password2" class="w200"/>
            </a-form-model-item>
            <a-form-item :wrapper-col="{ span: 12, offset: 6 }">
                <a-button type="primary" @click="resetPassword()">确认修改</a-button>
            </a-form-item>
        </a-form-model>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                labelCol: {span: 6},
                wrapperCol: {span: 14},
                form: {},
                pid: 0,
            };
        },
        mounted() {
        },
        methods: {
            resetPassword() {
                if (this.form.password1 === this.form.password2) {
                    this.$sa0.post({
                        url: this.$api('Edit_pwd'),
                        data: {
                            password: this.form.password,
                            password1: this.form.password1,
                            password2: this.form.password2,
                        }
                    }).then((response) => {
                        this.$sa0.response({
                            response: response.data,
                            then: (response) => {
                                layer.msg('修改成功')
                                this.form = {
                                    password: '',
                                    password1: '',
                                    password2: '',
                                }
                            },
                            error: (response) => {
                                layer.msg(response.message)
                            },
                        })
                    })
                } else {
                    layer.msg('两次密码不一致')
                }
            },
        }

    }
</script>
<style scoped>
    .pass-container{ width: 600px; height: 350px; background: #fdfdfd; position: absolute; left: 50%; top: 40%; transform: translate(-50%, -50%); border: 1px dashed #eeeeee; padding: 50px; padding-left: 100px }

    .w200{ width: 200px;}
</style>
